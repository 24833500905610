footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--footer-background);
    color: white;

    padding: 40px;

    .Inner {
        width: 1440px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    // Some CSS magic to add color to the logo
    img {
        width: 140px;
        filter: brightness(0) saturate(100%) invert(40%) sepia(83%)
            saturate(5423%) hue-rotate(163deg) brightness(94%) contrast(96%);
        margin-bottom: 12px;
    }

    span {
        margin: 1px 0px;
        text-align: right;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        cursor: pointer;
    }

    small {
        flex: content;
        margin-left: 4px;

        &.Label {
            color: var(--main);
            font-weight: 600;
        }
    }

    small.Label {
        flex: none;
    }

    svg {
        color: var(--main);
    }

    .Label,
    svg {
        margin-right: 4px;
    }

    @media (max-width: 768px) {
        padding: 14px;
        img {
            width: 100px;
        }
        small {
            margin: 4px 0px;
        }
    }
}
