:root {
    --main: #058a8d;
    --highlight: #07989b;

    --light: #8bc9c7;
    --footer-background: #2d2d35;
    --blue: #3a8df3;

    --button-background: var(--main);

    --payment-background: #1db4b0;

    /* Mostly for the Connect section */
    --input-background: #d8efef88;
    --input-border: #aac7c6;
    --submit-button-background: white;
    --submit-button-border: black;
}
