.Payment {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--payment-background);
    padding: calc(50px + 2vw) calc(24px + 1vw);

    position: relative;
    z-index: 1;

    h2 {
        color: white;
        text-align: center;
        margin-top: 0vw;
        margin-bottom: 0vw;
    }

    .IconContainer {
        flex: auto;
        background-color: white;
        border-radius: 18px;
        padding: 14px 16px;
        margin-top: calc(25px + 0.2vw);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        max-width: 800px;
    }

    img {
        max-height: 68px;
        max-width: 80px;
        margin: 18px 8px;
    }

    @media (max-width: 768px) {
        img {
            max-height: 32px;
            max-width: 96px;
        }
    }

    @media (min-width: 1440px) {
        padding: 60px 0px;
    }
}
