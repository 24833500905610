.Section {
    align-self: center;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;

    background-position: top left;
    background-size: 30vw;
    position: relative;

    display: flex;
    flex-direction: column;

    .LeftPane {
        width: 60%;
        padding: calc(40px + 2vw);
        z-index: 1;
        @media (min-width: 1440px) {
            padding: 128px 72px;
        }

        h2 {
            color: black;
            margin: 0px;
            width: fit-content;
        }

        .TopTitle {
            margin-top: 0px;
        }

        p {
            color: black;
        }

        @media (max-width: 768px) {
            width: unset !important;
            padding-left: 14px;
            padding-right: 14px;
        }
    }

    // For sections with children, move the bottom padding below the children
    // instead of below the left pane
    &.WithChildren {
        padding-bottom: calc(40px + 2vw);
        @media (min-width: 1440px) {
            padding-bottom: 128px;
        }
        .LeftPane {
            padding-bottom: 0vw;
        }
    }
}

.Section.Banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;

    height: 40vw;
    max-height: 576px;
    background-position: center top;
    background-size: cover;

    video {
        max-height: 576px;
        object-fit: cover;
    }

    .LeftPane {
        width: 60%;
        position: absolute;
    }

    h2,
    h3,
    h4,
    h1 {
        color: white;
        // text-shadow: 1px 1px 4px rgb(68, 68, 68);
        font-weight: 500;
        margin-bottom: 0px;
    }
    p {
        color: white;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    // Dark tint overlay
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2); //Change as per your requirement
    }

    @media (max-width: 768px) {
        min-height: unset;
        height: 300px;
        video {
            height: 300px;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.4); //Change as per your requirement
        }
    }
}

.Section.ImageBack {
    height: 40vw;
    max-height: 800px;
    background-position: center bottom;
    background-size: cover;

    .LeftPane {
        width: 40%;
    }
    .TopTitle {
        color: var(--light);
        margin-bottom: 8px;
    }
    h2 {
        color: white;
        font-weight: 500;
        margin-top: 0px;
    }
    p {
        color: white;
    }

    // Dark tint overlay
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2); //change as per your requirement
    }

    @media (max-width: 768px) {
        // Make the overlay even darker for mobile screens
        &:before {
            background: rgba(0, 0, 0, 0.4); //change as per your requirement
        }
    }
}

.Section.WhiteBack {
    h2 {
        color: var(--main);
    }
    p {
        color: black;
    }
}

.Section.GreenBack {
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    background-color: var(--payment-background);
}
