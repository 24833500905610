.NavButton {
    position: relative;
    cursor: pointer;
    padding-bottom: 14px;
    transform: translateY(8px);

    // To disable blue outline on Safari
    outline: none !important;

    .Chevron {
        display: none;
    }

    &.Selected:after {
        content: "";
        width: 24px;
        height: 2px;
        background: white;
        position: absolute;
        left: 14px;
        bottom: -4px;
        transform: translateY(-14px);
    }

    .Children {
        display: none;
        flex-direction: column;
        position: absolute;

        transform: translateY(0px);
        margin-top: 14px;
        background-color: var(--highlight);
        padding-bottom: 8px;

        .NavButton:first {
            margin-top: 15px;
        }
        .NavButton {
            margin: 4px;
        }
    }

    &:hover {
        .Children {
            display: flex;
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 8px 0px;
        margin: 2px 0px;

        &:hover {
            .Children {
                display: none;
            }
        }

        .Chevron {
            display: block;
        }
        .Children {
            display: none;
        }
        .MobileChildren {
            display: block;
        }
        &.Selected {
            background: #54aba8;
        }
        &.Selected:after {
            background: none;
        }
    }
}

.MobileChildren {
    display: none;
}

@media (max-width: 768px) {
    .MobileChildren {
        display: flex;
        flex-direction: column;
        margin-left: 14px;
    }
}
