h1,
h2,
h3,
small,
p,
a,
button,
input,
textarea {
    font-family: Futura;
    font-weight: 500;
}

h1 {
    font-size: 4.5vw;
    line-height: 6vw;
}

h2 {
    font-weight: 600;
    font-size: 3.1vw;
}

h3 {
    font-size: 2.1vw;
}

h4 {
    font-size: 1.3vw;
}

a {
    text-decoration: none;
    &.underlined {
        text-decoration: underline;
    }
}

small {
    font-size: calc(12px + 0.2vw);
}

p,
a {
    font-size: calc(12px + 0.2vw);
}

@media (max-width: 768px) {
    h1 {
        font-size: 30px;
        line-height: 38px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 15px;
    }

    small {
        font-size: 10px;
    }

    p,
    a {
        font-size: 15px;
    }
    button {
        font-size: 15px !important;
    }
}

@media (min-width: 1440px) {
    h1 {
        font-size: 65px;
        line-height: 80px;
    }
    h2 {
        font-size: 46px;
    }

    h3 {
        font-size: 32px;
    }
    h4 {
        font-size: 20px;
    }

    small {
        font-size: 16px;
    }

    p,
    a {
        font-size: 18px;
    }
    button {
        font-size: 18px !important;
    }
}

button {
    background: var(--button-background);
    color: white;
    padding: 8px 48px;
    font-size: 15px;
    font-size: 1.2vw;
    border: none;
    cursor: pointer;
}

.App {
    background-size: cover;
    background-color: white;
    overflow: hidden;
    padding-top: 78px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 768px) {
        padding-top: 50px;
    }

    @media (min-width: 1440px) {
        & > div {
            width: 1440px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
