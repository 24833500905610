header {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--main);

    .Inner {
        width: 1440px;
    }

    .Buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;

        padding: 20px 40px 14px 30px;

        & > span {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        img {
            width: 120px;
            transform: translate(-8px);
            margin-right: 14px;
            cursor: pointer;
        }

        small {
            margin: 0px 14px;
            cursor: pointer;
            user-select: none;
        }

        svg {
            margin-left: 14px;
            cursor: pointer;
        }

        .MenuButton {
            display: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        }
    }

    @media (max-width: 768px) {
        .Buttons {
            padding: 14px 8px 12px 14px;
            img {
                width: 50px;
                transform: translate(0px);
            }
            .NavButton,
            small,
            svg {
                display: none;
            }
            .MenuButton {
                display: inline;
                user-select: none;
                margin-right: 8px;
            }
        }
    }
}

// The dark overlay over the screen when the sidebar appears
.MenuBackground {
    position: fixed;
    top: 50px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    transition: opacity 0.2s;
    background-color: black;
    z-index: 4;
}

// The actual "sidebar"
.MenuContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50px;
    right: 0px;
    bottom: 0px;

    background-color: #207979;
    z-index: 10;
    color: white;
    width: 200px;

    text-align: center;
    transition: visibility 0.2s, max-width 0.2s;
}
