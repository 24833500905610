.Stakeholder {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: calc(20px + 0vw);
    padding-bottom: calc(20px + 0vw);

    .Pane {
        margin-left: -15%;
        display: flex;
        flex-direction: column;

        h3 {
            margin-left: calc(20px + 1vw);
            margin-right: calc(20px + 1vw);
            color: var(--main);
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 0px;
        }

        p {
            margin-left: calc(20px + 1vw);
            margin-right: calc(20px + 1vw);
        }

        .Quote {
            margin-left: calc(20px + 1vw);
            margin-right: calc(20px + 1vw);
            * {
                color: var(--main);
            }
            & > p {
                margin: 4px 0px;
            }
            small {
                margin: 0px;
            }
        }
    }

    img {
        height: 24vw;
        max-height: 346px;
    }

    .RightImage {
        display: none;
    }

    &.Right {
        justify-content: space-between;
        .Pane {
            margin-left: 0px;
            width: 60%;
        }
        .LeftImage {
            display: none;
        }
        .RightImage {
            display: flex;
            margin-left: auto;
        }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 48px;
        &:first-child {
            margin-top: 14px;
        }
        .Pane {
            width: unset !important;
            min-width: unset !important;
            margin: 0px;
            padding: 0vw 5vw;
            h3 {
                margin-left: 0px;
                margin-right: 0px;
            }
            p {
                margin: 14px 0px;
            }
            .Quote {
                text-align: right;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
        img {
            height: 60vw;
        }
        .LeftImage {
            display: none;
        }
        .RightImage {
            display: flex;
        }

        &.Right {
            .Pane {
                .Quote {
                    text-align: left;
                }
            }
            .RightImage {
                // margin-left: auto;
            }
        }
    }
}
