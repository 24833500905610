.SegmentsPage {
}
.Stakeholder-Operations {
	padding-top: 4vw;
	padding-bottom: 4vw;
	img {
		transform: scale(2.1) translate(-6vw, -2vw) !important;
	}
	@media (min-width: 1440px) {
		padding-top: 48px;
		padding-bottom: 24px;
		img {
			transform: scale(2.1) translate(-86px, -28px) !important
		}
	}

}
.Stakeholder-Manufacturers {
	padding-bottom: 0vw;
	img {
		transform: scale(1.2) translate(-3vw, 3vw) !important;
		align-self: flex-end;
	}
	@media (max-width: 768px) {
		img {
			transform: scale(1.2) translate(-10px, 31px) !important
		}
	}
	@media (min-width: 1440px) {
		padding-top: 84px;
		img {
			transform: scale(1.2) translate(-44px, 31px) !important
		}
	}
}
