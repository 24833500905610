@font-face {
    font-family: "Futura";
    src: url("../fonts/FuturaLT.woff2") format("woff2");
}

@font-face {
    font-family: "Futura";
    src: url("../fonts/FuturaLT-Bold.woff2") format("woff2");
    font-weight: bold;
}

@font-face {
    font-family: "Futura Book";
    src: url("../fonts/FuturaLT-Book.woff2") format("woff2");
    font-weight: normal;
}
