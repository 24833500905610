.Connect {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: calc(50px + 2vw) 0px;

    background-repeat: no-repeat;

    background-position: top left;
    background-size: 15vw;
    background-color: white;

    // Container for the title, desription, inputs and button
    .ContentContainer {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 800px;

        // Description
        & > p {
            margin: calc(25px + 0.2vw) 0px;
            max-width: 500px;
            text-align: center;
            align-self: center;
        }
    }

    // "Let's Connect" title
    h2 {
        color: black;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    // Container of all the inputs / text area
    .Inputs {
        display: flex;
        flex-direction: column;

        // The "Name" and "Email" fields
        & > span {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            input {
                flex: auto;
            }
        }
    }

    input,
    textarea {
        background-color: var(--input-background);
        padding: 8px;
        border: {
            color: var(--input-border);
            width: 1px;
            radius: 8px;
            style: solid;
        }
        margin: 4px 2px;
    }

    textarea {
        height: 140px;
    }

    .SubmitButton {
        color: black;
        background-color: var(--submit-button-background);
        border: {
            color: var(--submit-button-border);
            width: 1px;
            radius: 24px;
            style: solid;
        }
        align-self: center;
        margin-top: 24px;
        padding: 4px 14px;
        width: 60%;
    }

    .ErrorText {
        color: red;
        margin-top: 1rem;
    }

    .PositiveText {
        color: green;
        margin-top: 1rem;
    }

    @media (max-width: 768px) {
        .Inputs {
            width: 100%;
        }
        .SubmitButton {
            width: 200px;
        }
    }

    @media (min-width: 1440px) {
        padding: 60px 0px;
    }
}
